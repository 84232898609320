import React, { useState, useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  useGetProcessingStatusesQuery,
  //   useGetReviewStatusesQuery,
  useUpdateInvoiceStatusMutation,
} from "../../../features/InvoiceManager/invoiceSlice";
import toast from "react-hot-toast";

const InvoiceStatusModal = ({
  isOpen,
  toggle,
  selectedInvoice,
  setSelectedInvoice,
}) => {
  const formRef = useRef();
  //   const [reviewStatus, setReviewStatus] = useState([]);
  const [processStatus, setProcessStatus] = useState([]);
  //   const [selectedReview, setSelectedReview] = useState(null);
  const [selectedProcess, setSelectedProcess] = useState(null);

  const { data: processingStatuses } = useGetProcessingStatusesQuery();
  //   const { data: reviewStatuses } = useGetReviewStatusesQuery();
  const [updateInvoiceStatus] = useUpdateInvoiceStatusMutation();

  useEffect(() => {
    if (selectedInvoice) {
      //   setSelectedReview(selectedInvoice.reviewStatusId);
      setSelectedProcess(selectedInvoice.processingStatusId);
    }
  }, [selectedInvoice]);

  useEffect(() => {
    if (!isOpen) {
      //   setSelectedReview(null);
      setSelectedProcess(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (processingStatuses) {
      setProcessStatus(
        processingStatuses.ids.map((id) => processingStatuses.entities[id])
      );
    }
    // if (reviewStatuses) {
    //   setReviewStatus(
    //     reviewStatuses.ids.map((id) => reviewStatuses.entities[id])
    //   );
    // }
  }, [
    processingStatuses,
    // , reviewStatuses
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const requestPromise = updateInvoiceStatus({
        guid: selectedInvoice?.id,
        // reviewStatusId: selectedReview,
        processingStatusId: selectedProcess,
      }).unwrap();
      toast.promise(requestPromise, {
        loading: "Updating status...",
        success: "Status updated",
        error: (err) =>
          `Status update failed: ${err?.data?.Message || err?.data?.title}`,
      });
      setSelectedInvoice(null);
      toggle();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      size="sm"
      isOpen={isOpen}
      toggle={toggle}
      onClosed={() => setSelectedInvoice(null)}
      centered
    >
      <ModalHeader
        toggle={toggle}
        style={{ borderBottom: "none", paddingBottom: "0" }}
      >
        Select status
      </ModalHeader>
      <form className="container" onSubmit={handleSubmit} ref={formRef}>
        <ModalBody>
          <div className="row mb-1">
            {/* <div className="col">
              <span className="filter-title">Review Status</span>
              {reviewStatus.map(({ name, id }) => (
                <div className="form-check" key={id}>
                  <input
                    type="radio"
                    className="form-check-input"
                    name="reviewStatusGroup"
                    checked={selectedReview === id}
                    onChange={() => setSelectedReview(id)}
                  />
                  <label className="form-check-label">{name}</label>
                </div>
              ))}
            </div> */}

            <div className="col">
              <span className="filter-title">Processing Status</span>
              {processStatus.map(({ name, id }) => (
                <div className="form-check" key={id}>
                  <input
                    id={`processStatus${id}`}
                    type="radio"
                    className="form-check-input"
                    name="processingStatusGroup"
                    checked={selectedProcess === id}
                    onChange={() => setSelectedProcess(id)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`processStatus${id}`}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter style={{ borderTop: "none" }} className="row">
          <div className="col">
            <Button type="submit" className="btn-filter w-100">
              Update
            </Button>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default InvoiceStatusModal;
