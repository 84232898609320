import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const userAdapter = createEntityAdapter({
  selectId: (user) => user.id,
});

const userInitialState = userAdapter.getInitialState();

export const userSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: () => `/users`,
      transformResponse: (response) => {
        const loadedUsers = response;
        return userAdapter.addMany(userInitialState, loadedUsers);
      },
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "Users", id: "LIST" }];
        } else {
          return [
            { type: "Users", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Users", id })),
          ];
        }
      },
    }),
    getUserSettings: builder.query({
      query: (guid) => `/users/${guid}/settings`,
      providesTags: (result, error, arg) => {
        if (error) {
          return [{ type: "UserSettings", id: "ERROR" }];
        } else {
          return [{ type: "UserSettings", id: arg }];
        }
      },
    }),
    updateNotificationSettings: builder.mutation({
      query: ({ guid, data }) => {
        return {
          url: `/users/${guid}/settings`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: [{ type: "UserSettings" }],
    }),
  }),
});

export const {
  useGetUsersQuery,
  useGetUserSettingsQuery,
  useUpdateNotificationSettingsMutation,
} = userSlice;
