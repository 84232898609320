import React, { useEffect } from "react";
import { FormGroup, Input } from "reactstrap";
import {
  useGetUserSettingsQuery,
  useUpdateNotificationSettingsMutation,
} from "../Users/UsersSlice";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../auth/authSlice";
import toast, { Toaster } from "react-hot-toast";

const Settings = () => {
  const user = useSelector(selectCurrentUser);

  const {
    data,
    isLoading: dataIsLoading,
    error,
  } = useGetUserSettingsQuery(user?.guid, {
    skip: !user?.guid,
  });

  const [updateSettings, { isLoading: dataIsUpdating }] =
    useUpdateNotificationSettingsMutation();

  useEffect(() => {
    if (error) {
      toast.error(
        `Failed to fetch user settings:${
          error?.data?.Message || error?.data?.title
        }`
      );
    }
  }, [error]);

  const handleToggleNotifications = async (e) => {
    try {
      const requestResponse = updateSettings({
        guid: user?.guid,
        data: {
          isDailyRemindersEnabled: e.target.checked,
        },
      }).unwrap();
      toast.promise(requestResponse, {
        loading: "Updating Notification settings...",
        success: "Updated Successfully",
        error: (err) =>
          `Notification update settings failed: ${
            err?.data?.Message || err?.data?.title
          }`,
      });
      await requestResponse;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="full-canvas">
        <div className="custom-card">
          <h5 className="border-bottom py-2 pb-3 mb-4">Settings</h5>
          <div className="card d-inline-block p-4">
            <h4 className="m-0">Notifications</h4>
            <p className="text-secondary">
              Configure how you receive notifications.
            </p>
            <h6>Enable daily reminders on assigned invoices</h6>
            <div className="d-flex justify-content-between gap-5">
              <p>Receive a daily reminder for invoices assigned to you.</p>
              <FormGroup switch>
                <Input
                  type="switch"
                  checked={data?.isDailyRemindersEnabled || false}
                  onChange={handleToggleNotifications}
                  aria-label="Toggle daily reminders"
                  disabled={dataIsLoading || dataIsUpdating}
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
