import React, { useState, useEffect } from "react";
import "./ApprovalRequests.css";
import approvalIcon from "../../assets/images/Icons/approvalIcon.svg";
import { Toaster } from "react-hot-toast";
import toast from "react-hot-toast";
import manualIcon from "../../assets/images/Icons/manualRequired.svg";
import {
  Nav,
  NavItem,
  TabPane,
  TabContent,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import { format, formatDistanceToNow, parse, parseISO } from "date-fns";
import {
  useGetApprovalRequireInvoicesQuery,
  useGetRequireToApprovalInvoicesQuery,
} from "./ApprovalRequestSlice";
import ApproveOrRejectInvoice from "../../components/InvoiceManager/ApproveOrRejectInvoice/ApproveOrRejectInvoice";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import {
  useApproveInvoiceMutation,
  useRejectInvoiceMutation,
} from "../InvoiceManager/invoiceSlice";

import { currencyFormatter } from "../../components/common/utils/utils";
import EditInvoice from "../../components/InvoiceManager/EditInvoice/EditInvoice";
import { useNavigate } from "react-router-dom";
import { PagerComponent } from "@syncfusion/ej2-react-grids";
import { useErrorToast } from "../../hooks/useErrorToast";

const ApprovalRequests = () => {
  const navigate = useNavigate();
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState("");
  const [action, setAction] = useState();
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const [guid, setGuid] = useState("");
  const [tabId, setTabId] = useState(1);
  const [rejectedInvoices, setRejectedInvoices] = useState([]);
  const [approvedInvoices, setApprovedInvoices] = useState([]);
  const [requireToapproveInvoices, setRequireToApprovedInvoices] = useState([]);

  const [totalRecords, setTotalRecords] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(11);
  const [totalGreaterThan, setTotalGreaterThan] = useState("");
  const [totalLessThan, setTotalLessThan] = useState("");
  const [query, setQuery] = useState("");
  const [assignedToId, setAssignedToId] = useState("");
  const [createdById, setCreatedById] = useState("");
  const [updatedById, setUpdatedById] = useState("");
  const [updatedOnLessThan, setUpdatedOnLessThan] = useState("");
  const [updatedOnGreaterThan, setUpdatedOnGreaterThan] = useState("");
  const [createdOnLessThan, setCreatedOnLessThan] = useState("");
  const [createdOnGreaterThan, setCreatedOnGreaterThan] = useState("");
  const [reviewTypeId, setReviewTypeId] = useState(null);
  const [processingStatusId, setProcessingStatusId] = useState(null);
  const [reviewStatusId, setReviewStatusId] = useState(null);
  const showErrorToast = useErrorToast();

  const {
    data: invoiceDetailsList,
    isLoading: isInvoiceDataLoading,
    isError: invoiceDetailsListIsError,
    error: invoiceDetailsListError,
  } = useGetApprovalRequireInvoicesQuery({ statusId: "6" });

  const {
    data: approvedinvoiceDetailsList,
    isLoading: approvedinvoiceDataLoading,
  } = useGetApprovalRequireInvoicesQuery({ statusId: "7" });

  const {
    data: rejectedInvoicesDetailsList,
    isLoading: rejectinvoiceDataLoading,
  } = useGetApprovalRequireInvoicesQuery({ statusId: "8" });

  const {
    data: getRequireToApprovalInvoicesDetailsList,
    isLoading: requireToApprovalInvoiceIsLoading,
    isError: requireToApprovalInvoiceIsError,
    error: requireToApprovalInvoiceError,
  } = useGetRequireToApprovalInvoicesQuery({
    page: page,
    pageSize: pageSize,
    totalGreaterThan: totalGreaterThan,
    totalLessThan: totalLessThan,
    query: query,
    assignedToId: assignedToId,
    createdById: createdById,
    updatedById: updatedById,
    updatedOnLessThan: updatedOnLessThan,
    updatedOnGreaterThan: updatedOnGreaterThan,
    createdOnLessThan: createdOnLessThan,
    createdOnGreaterThan: createdOnGreaterThan,
    reviewTypeId: reviewTypeId,
    processingStatusId: processingStatusId,
    reviewStatusId: reviewStatusId,
  });

  const [
    approveInvoice,
    {
      isLoading: approveInvoiceIsLoading,
      isSuccess: approveInvoiceIsSuccess,
      isError: approveInvoiceIsError,
      error: approveInvoiceError,
    },
  ] = useApproveInvoiceMutation();

  const [
    rejectInvoice,
    {
      isLoading: rejectInvoiceIsLoading,
      isSuccess: rejectInvoiceIsSuccess,
      isError: rejectInvoiceIsError,
      error: rejectInvoiceError,
    },
  ] = useRejectInvoiceMutation();

  useEffect(() => {
    if (invoiceDetailsList) {
      let data = invoiceDetailsList.ids.map((id) => {
        const items = invoiceDetailsList.entities[id];
        return items;
      });
      setInvoiceDetails(data);
    }
    if (invoiceDetailsListIsError) {
      showErrorToast(invoiceDetailsListError);
    }
  }, [
    invoiceDetailsList,
    approvedinvoiceDetailsList,
    rejectedInvoicesDetailsList,
  ]);

  useEffect(() => {
    if (rejectedInvoicesDetailsList) {
      let data = rejectedInvoicesDetailsList.ids.map((id) => {
        const items = rejectedInvoicesDetailsList.entities[id];
        return items;
      });

      setRejectedInvoices(data);
    }
  }, [rejectedInvoicesDetailsList]);

  useEffect(() => {
    if (approvedinvoiceDetailsList) {
      let data = approvedinvoiceDetailsList.ids.map((id) => {
        const items = approvedinvoiceDetailsList.entities[id];
        return items;
      });

      setApprovedInvoices(data);
    }
  }, [
    approvedinvoiceDetailsList,
    invoiceDetailsList,
    rejectedInvoicesDetailsList,
  ]);

  useEffect(() => {
    if (getRequireToApprovalInvoicesDetailsList) {
      let data = getRequireToApprovalInvoicesDetailsList.ids.map((id) => {
        const items = getRequireToApprovalInvoicesDetailsList.entities[id];
        return items;
      });
      setTotalRecords(data[0]?.pageSettings?.totalRecordsCount);
      setRequireToApprovedInvoices(data);
    }
    if (requireToApprovalInvoiceIsError) {
      showErrorToast(requireToApprovalInvoiceError);
    }
  }, [
    getRequireToApprovalInvoicesDetailsList,
    invoiceDetailsList,
    approvedinvoiceDetailsList,
    rejectedInvoicesDetailsList,
    requireToApprovalInvoiceIsError,
  ]);

  const [open, setOpen] = useState("");
  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const handleClick = (e, guid) => {
    if (e.target.value === "approve") {
      setAction("approve");
      setGuid(guid);
      setStatus("approve");
    }
    if (e.target.value === "reject") {
      setAction("reject");
      setGuid(guid);
      setStatus("reject");
    }
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (action) {
      setAction("");
    }
  }, [action]);

  const handleRest = () => {
    setComment("");
    setReason("");
    setGuid("");
    setStatus("");
  };

  const approveInvoiceOnClick = async () => {
    const canSave = Boolean(comment);
    if (!canSave) {
      if (comment === "") {
        toast.error("comment is required...!");
      }
      return;
    }
    try {
      const response = await approveInvoice({
        guid: guid,
        data: {
          comment: comment,
        },
      }).unwrap();

      toast.success("Invoice Approved Successful", {
        duration: 3000,
      });
      handleRest();
    } catch (error) {
      showErrorToast(error);
    }
  };

  const rejectInvoiceOnClick = async () => {
    const canSave = Boolean(comment);
    if (!canSave) {
      if (comment === "") {
        toast.error("comment is required...!");
      }
      if (reason === "") {
        toast.error("reason is required...!");
      }
      return;
    }
    try {
      const response = await rejectInvoice({
        guid: guid,
        data: {
          guid: guid,
          comment: comment,
          reason: reason,
        },
      }).unwrap();

      toast.success("Invoice Rejected Successful", {
        duration: 3000,
      });
      handleRest();
    } catch (error) {
      showErrorToast(error);
    }
  };

  const goto = (id) => {
    navigate(`/invoice/edit/${id}`);
  };

  const handlePageClick = (args) => {
    setPage(args.currentPage);
  };

  return (
    <div className="approval-requests">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div className="custom-card">
        <div className="row">
          <div className="col-12">
            <div>
              <Nav pills className="mb-3 pt-2 accordian-navbar">
                <NavItem className="me-4">
                  <button
                    className={`btn btn-tab ${tabId === 1 ? "active-tab" : ""}`}
                    onClick={() => setTabId(1)}
                  >
                    Approval Required
                    {requireToapproveInvoices.length > 0 && (
                      <span className="item-count">{totalRecords}</span>
                    )}
                  </button>
                </NavItem>
                <NavItem className="me-4">
                  <button
                    className={`btn btn-tab ${tabId === 3 ? "active-tab" : ""}`}
                    onClick={() => setTabId(3)}
                  >
                    Approved Invoices
                  </button>
                </NavItem>
                <NavItem className="me-4">
                  <button
                    className={`btn btn-tab ${tabId === 4 ? "active-tab" : ""}`}
                    onClick={() => setTabId(4)}
                  >
                    Rejected Invoices
                  </button>
                </NavItem>
              </Nav>
              <TabContent activeTab={tabId}>
                <TabPane tabId={1}>
                  <Accordion flush open={open} toggle={toggle}>
                    {isInvoiceDataLoading ||
                    approveInvoiceIsLoading ||
                    rejectInvoiceIsLoading ? (
                      <LoadingSpinner />
                    ) : (
                      requireToapproveInvoices.map((request) => (
                        <AccordionItem key={request.guid}>
                          <AccordionHeader targetId={request.guid}>
                            <img
                              src={approvalIcon}
                              alt="Avatar"
                              className="mb-3 me-2"
                            />
                            <p className="m-0">
                              {`Invoice ${request.invoiceNumber} has a total amount of ${request.totalAmount}`}
                              <br />
                              <small>
                                {`Last Updated on ${
                                  request.updatedOn
                                    ? formatDistanceToNow(
                                        new Date(request.updatedOn)
                                      ) + " ago"
                                    : "N/A"
                                } `}
                              </small>
                            </p>
                          </AccordionHeader>
                          <AccordionBody accordionId={request.guid}>
                            <div className="details-container">
                              <EditInvoice id={request?.guid} viewOnly={true} />
                            </div>

                            <div className="row justify-content-end">
                              <div className="col-auto">
                                <button
                                  className="btn btn-sm btn-blue me-2 fw-bold"
                                  onClick={(e) => handleClick(e, request.guid)}
                                  value="approve"
                                >
                                  Approve
                                </button>
                                <button
                                  className="btn btn-sm btn-danger fw-bold"
                                  onClick={(e) => handleClick(e, request.guid)}
                                  value="reject"
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      ))
                    )}
                  </Accordion>
                  {requireToapproveInvoices.length > 0 ? (
                    <PagerComponent
                      pageSize={pageSize}
                      totalRecordsCount={totalRecords}
                      click={handlePageClick}
                    />
                  ) : (
                    "You don't have any invoices to approve"
                  )}
                </TabPane>
                <TabPane tabId={2}>
                  {!invoiceDetails.length > 0 &&
                    "You don't have any invoices available"}
                  <Accordion flush open={open} toggle={toggle}>
                    {requireToApprovalInvoiceIsLoading ||
                    approveInvoiceIsLoading ||
                    rejectInvoiceIsLoading ? (
                      <LoadingSpinner />
                    ) : (
                      invoiceDetails.map((request) => (
                        <AccordionItem key={request.guid}>
                          <AccordionHeader targetId={request.guid}>
                            <img
                              src={manualIcon}
                              alt="Avatar"
                              className="mb-3 me-2"
                            />
                            <p className="m-0">
                              {`Invoice ${request.invoiceNumber} has a total amount of ${request.totalAmount}`}
                              <br />
                              <small>
                                {/* {formatDistanceToNow(request.recivedTime)} */}
                              </small>
                            </p>
                          </AccordionHeader>
                          <AccordionBody accordionId={request.guid}>
                            <div className="row gap-3">
                              {request?.data.map((d) => (
                                <div className="col-6 col-md-3" key={d.fieldId}>
                                  <p className="fw-bold  m-0 small">
                                    {d.fieldName}
                                  </p>

                                  <span>
                                    {d.fieldName === "Net Amount" ||
                                    d.fieldName === "Tax Value" ||
                                    d.fieldName === "Total Amount"
                                      ? currencyFormatter(
                                          parseFloat(d.fieldValue)
                                        )
                                      : d.fieldName === "Invoice Date"
                                      ? d.fieldValue
                                        ? format(
                                            parse(
                                              d.fieldValue,
                                              "MM/dd/yyyy HH:mm:ss",
                                              new Date()
                                            ),
                                            "dd/MM/yyyy"
                                          )
                                        : "N/A"
                                      : d.fieldValue}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div className="row justify-content-end">
                              <div className="col-auto">
                                <button
                                  className="btn btn-sm btn-blue me-2 fw-bold"
                                  onClick={(e) => handleClick(e, request.guid)}
                                  value="approve"
                                >
                                  Approve
                                </button>
                                <button
                                  className="btn btn-sm btn-danger fw-bold"
                                  onClick={(e) => handleClick(e, request.guid)}
                                  value="reject"
                                >
                                  Reject
                                </button>
                              </div>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      ))
                    )}
                  </Accordion>
                </TabPane>
                <TabPane tabId={3}>
                  {!approvedInvoices.length > 0 &&
                    "You don't have any approved invoices"}
                  <Accordion flush open={open} toggle={toggle}>
                    {approvedinvoiceDataLoading ? (
                      <LoadingSpinner />
                    ) : (
                      approvedInvoices.map((request) => (
                        <AccordionItem key={request.guid}>
                          <AccordionHeader targetId={request.guid}>
                            <img
                              src={approvalIcon}
                              alt="Avatar"
                              className="mb-3 me-2"
                            />
                            <p className="m-0">
                              {`Invoice ${request.invoiceNumber} has a total amount of ${request.totalAmount}`}
                              <br />
                              <small>
                                {`Last Updated on ${
                                  request.updatedOn
                                    ? formatDistanceToNow(
                                        new Date(request.updatedOn)
                                      ) + " ago"
                                    : "N/A"
                                }`}
                              </small>
                            </p>
                          </AccordionHeader>
                          <AccordionBody accordionId={request.guid}>
                            <div className="row gap-3 mb-1">
                              {request?.data.map((d) => (
                                <div className="col-6 col-md-3" key={d.fieldId}>
                                  <p className="fw-bold  m-0 small">
                                    {d.fieldName}
                                  </p>

                                  <span>
                                    {d.fieldName === "Net Amount" ||
                                    d.fieldName === "Tax Value" ||
                                    d.fieldName === "Total Amount"
                                      ? currencyFormatter(
                                          parseFloat(d.fieldValue)
                                        )
                                      : d.fieldName === "Invoice Date"
                                      ? d.fieldValue
                                        ? format(
                                            parse(
                                              d.fieldValue,
                                              "MM/dd/yyyy HH:mm:ss",
                                              new Date()
                                            ),
                                            "dd/MM/yyyy"
                                          )
                                        : "N/A"
                                      : d.fieldValue}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div>
                              <button
                                className="btn btn-sm btn-filter"
                                onClick={() => goto(request.guid)}
                              >
                                View
                              </button>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      ))
                    )}
                  </Accordion>
                </TabPane>
                <TabPane tabId={4}>
                  {!rejectedInvoices.length > 0 &&
                    "You don't have any rejected invoices"}
                  <Accordion flush open={open} toggle={toggle}>
                    {rejectinvoiceDataLoading ? (
                      <LoadingSpinner />
                    ) : (
                      rejectedInvoices.map((request) => (
                        <AccordionItem key={request.guid}>
                          <AccordionHeader targetId={request.guid}>
                            <img
                              src={manualIcon}
                              alt="Avatar"
                              className="mb-3 me-2"
                            />
                            <p className="m-0">
                              {`Invoice ${request.invoiceNumber} has a total amount of ${request.totalAmount}`}
                              <br />
                              <small>
                                {`Last Updated on ${
                                  request.updatedOn
                                    ? formatDistanceToNow(
                                        new Date(request.updatedOn)
                                      ) + " ago"
                                    : "N/A"
                                } `}
                              </small>
                            </p>
                          </AccordionHeader>
                          <AccordionBody accordionId={request.guid}>
                            <div className="row gap-3 mb-1">
                              {request?.data.map((d) => (
                                <div className="col-6 col-md-3" key={d.fieldId}>
                                  <p className="fw-bold  m-0 small">
                                    {d.fieldName}
                                  </p>

                                  <span>
                                    {d.fieldName === "Net Amount" ||
                                    d.fieldName === "Tax Value" ||
                                    d.fieldName === "Total Amount"
                                      ? currencyFormatter(
                                          parseFloat(d.fieldValue)
                                        )
                                      : d.fieldName === "Invoice Date"
                                      ? d.fieldValue
                                        ? format(
                                            parse(
                                              d.fieldValue,
                                              "MM/dd/yyyy HH:mm:ss",
                                              new Date()
                                            ),
                                            "dd/MM/yyyy"
                                          )
                                        : "N/A"
                                      : d.fieldValue}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div>
                              <button
                                className="btn btn-sm btn-filter"
                                onClick={() => goto(request.guid)}
                              >
                                View
                              </button>
                            </div>
                          </AccordionBody>
                        </AccordionItem>
                      ))
                    )}
                  </Accordion>
                </TabPane>
              </TabContent>
            </div>
          </div>
          <ApproveOrRejectInvoice
            toggle={toggleModal}
            action={action}
            setComment={setComment}
            setReason={setReason}
            onClickFunction={
              status === "approve"
                ? approveInvoiceOnClick
                : rejectInvoiceOnClick
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ApprovalRequests;
